import React from "react";
import "./Footer.css";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logoWhite from "../../assets/images/logo-white.svg";
import { Link } from "react-router-dom";

const Footer = (props) => {

  return (
    <>
      <div className="pt-12 px-2 foot">
        <div className="max-w-screen-xl mx-auto pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="mx-auto md:mx-0">
              <img src={logoWhite} alt="white logo" />
            </div>
            <div className="text-white pt-12 mx-auto md:mx-0 flex flex-row justify-end space-x-8">
              <FaFacebookF />
              <FaTwitter /> 
              <FaInstagram />
              <a href="https://www.linkedin.com/company/omnienergyco" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto pt-12">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="text-white text-left">
              <p className="pb-4">Our Sectors</p>

              
              <Link to="/smart-energy" >
              <p className="font-light pb-2 cursor-pointer">Hybrid Energy Systems</p>
              </Link>
              <Link to="/electric-mobility">
              <p className="font-light pb-2 cursor-pointer">Electric Mobility</p>
              </Link>
              <Link to="/integrative-efficiencies">
              <p className="font-light pb-2 cursor-pointer">Integrative Efficiencies</p>
              </Link>
            </div>

            <div className="pt-12 md:pt-0">
              <p className="text-white font-light pb-8">
                Subscribe to our awesome newsletter
              </p>
              <div className="flex">
                <input
                  className="bg-transparent w-full outline-none mr-4 py-2 mb-4 border-b border-white focus:border-secondary"
                  placeholder="Email"
                  type="email"
                />
                <button className="bg-white text-primary px-6 py-2 rounded-full mx-auto md:mx-0">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto pt-12">
          {/* <p className="text-white text-center font-thin py-5">
            +2348120506414
          </p> */}
          <p className="text-white text-center font-thin py-5">everywhere@omnienergy.systems</p>
          <p className="text-white text-center font-thin py-5">
            Q Building, Beach Gate Road, Jakande, Lekki, Lagos Nigeria.
          </p>
          <p className="text-white text-center text-sm font-thin py-5">
            Copyright © Omnienergy Ltd 2023. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
